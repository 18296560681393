import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";


import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
// import Admin from './pages/Admin';
import NotFound from './pages/NotFound';

import "./scss/App.scss";

function App() {

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://tm.jsuol.com.br/uoltm.js?id=drfihs";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="page-main">
        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/admin/:person" component={Home} />
          {/* <Route exact path="/admin/:person" component={Admin} /> */}
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
