import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import '../scss/Footer.scss';

function Footer() {
  return (
    <footer id="footer">
      <Container maxWidth="md" className="container">
        <Typography className="date" variant="body2" component="p" color="textSecondary">
          © 1996 - {new Date().getFullYear()} UOL O melhor conteúdo . Todos os direitos reservados
        </Typography>
        <Typography className="address" variant="body2" component="p" color="textSecondary">
          UNIVERSO ONLINE S/A - CNPJ/MF 01.109.184/0001-95 Av. Brigadeiro Faria Lima, 1.384, São Paulo/SP - CEP 01452-002
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
