import '../scss/NavBar.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button";

import { logout } from "./logout";

function NavBar() {
  return (
      <AppBar id="navbar" position="relative">
        <Container maxWidth="md">
          <Toolbar className="header">
            <div className="wrapper">
              <Link className="title" to="/">
                <img
                  className="logo"
                  src="https://imguol.com/p/antivirus/painel/logo-antivirus-preto.svg"
                  alt="UOL Antiv&iacute;rus"
                  height={30}
                  width="auto"
                />
              </Link>

              <Button variant="outlined" className="logout" onClick={logout}>
                Sair
              </Button>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
  );
}

export default NavBar;