import React, { useEffect, useState, useCallback } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Subscribes from "../components/Subscribes";
import Tutorials from "../components/Tutorials";
// import Message from "../components/Message";
import { GetDataBlock, GetPlatform, GetTutorials, } from "../api";

import "../scss/Home.scss";

const loginURL  = 'https://conta.uol.com.br/login?t=cad-antivirus&dest=';

function Home() {
  const [unicdata, setUnicdata] = useState(false);
  const [person, setPerson] = useState(false);
  // const [name, setName] = useState(false);
  const [inscriptions, setInscriptions] = useState(false);
  // const [message, setMessage] = useState(false);
  const [tutorials, setTutorials] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // OLD
  // useEffect(() => {
  //   if (person) {
  //     loadUserName(person);
  //     loadInscriptions(person);
  //   } else {
  //     getPerson();
  //   }
  //   loadTutorials();
  //   // eslint-disable-next-line
  // }, [person]);

  useEffect(() => {
    if (unicdata) {
      setPerson(unicdata.person);
      // setName(unicdata.person.name);
      loadInscriptions(unicdata.inscriptons);
    } else {
      getUnicData();
    }

    loadTutorials();
    // eslint-disable-next-line
  }, [unicdata]);

  const getUnicData = useCallback(() => {

    const platform = GetPlatform();

    GetDataBlock(platform)
      .then(({ data }) => {

        const { unicData } = data;

        setUnicdata(unicData);

      })
      .catch((error) => {
        console.warn({...error, message: 'Não foi possível carregar os planos do usuário: ' + error.message});
        const currentHost = window.location.protocol + '//' + window.location.host
        window.location.href = loginURL + currentHost;
      });
    // eslint-disable-next-line
  }, []);

  // const getPerson = useCallback(() => {
  //   GetUserSession()
  //     .then(({ data }) => {
  //       console.log(data);
  //       const { person, unicData } = data;
  //       console.log("person ", person);
  //       console.log("unicData ", unicData);
  //       setPerson(person);
  //     })
  //     .catch(() => {
  //       window.location.href = "https://conta.uol.com.br/login?t=segurancadigital&dest=https://painel.seguranca.uol.com.br/"
  //     });
  //   // eslint-disable-next-line
  // }, []);

  // const loadUserName = (person) => {
  //   GetName(person.idPerson)
  //     .then(({ data }) => {
  //       setName(data);
  //     }).catch((error) => console.log({ message: 'Não foi possível carregar o nome do usuário.', error: error }));
  // }

  const loadInscriptions = (inscrs) => {
    try {
      setInscriptions(inscrs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true)
    }
  }
  // const loadInscriptions = (person) => {
  //   GetInscriptions(person.idPerson, GetPlatform())
  //     .then(({ data }) => {
  //       setInscriptions(data);
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //       setError(true)
  //     }
  //     );
  // }

  const loadTutorials = () => {
    GetTutorials()
      .then(({ data }) => {
        // if (data.mensagem.conteudo.severity === "off") {
        //   setMessage(false);
        // } else {
        //   setMessage({
        //     text: data.mensagem.conteudo.texto,
        //     severity: data.mensagem.conteudo.severity
        //   });
        // }
        setTutorials(data.dicas);
      })
      .catch((error) => console.log({ message: 'Não foi possível carregar as Dicas e Tutoriais.', error: error }));
  }

  const changeLoading = (value) => setLoading(value);

  return (
    <>
      {loading && <LinearProgress className="progress" />}
      <div id="home">
        <Container maxWidth="md">
          {
            person && (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    {/* {message && <Message message={message} />} */}
                    <div className="user">
                      <Typography component="h3" className="name">
                        {person.name ? `Olá, ${person.name}!` : `Conta ${person.login}`}
                      </Typography>
                      <Typography component="p" className="welcome">
                        Boas vindas ao painel de gerenciamento das suas assinaturas!
                      </Typography>
                    </div>
                  </Grid>
                  {
                    error &&
                    <div className="inscriptions-error">
                      <ErrorOutlineIcon className="icon" />
                      <Typography component="h3">Não foi possível carregar seus produtos.</Typography>
                      <Typography component="small">Erro no recurso /info/user/inscriptions</Typography>
                    </div>
                  }
                  {inscriptions && <Subscribes inscriptions={inscriptions} loading={changeLoading} />}
                </Grid>
                <Tutorials tutorials={tutorials} />
              </>
            )
          }
        </Container>
      </div>
    </>
  );
}

export default Home;
