import axios from "axios";

export const GetDataBlock = (platform) => axios.get("/info/user/datablock", { params: { platform: platform } });

export const GetUserSession = () => axios.get("/info/user/session");

export const GetInscriptions = (idtPerson) => axios.get("/info/user/inscriptions", { params: { idtPerson: idtPerson } });

export const GetName = (idtPerson) => axios.get("/info/user/name", { params: { idtPerson: idtPerson } });

export const GetInfos = (idtInscription) => axios.get("/info/user/infos", { params: { idtInscription: idtInscription } });

export const GetDownloadLink = (idtInscription, platform) => axios.get('/info/user/download/link', { params: { idtInscription: idtInscription, platform: platform } });

export const GetTutorials = () => axios.get(`https://jsuol.com.br/exports/seguranca-digital/message.json?v=${new Date().getTime()}`);


export const GetPlatform = () => {

  if (navigator.userAgent.match(/Android/i)) {
    return "ANDROID";
  }

  if (navigator.userAgent.match(/Macintosh/i)) {
    return "MAC";
  }

  if (navigator.userAgent.match(/Windows/i)) {
    return "WINDOWS";
  }

  return false;
};

export default {
  GetInscriptions,
  GetInfos,
  GetName,
  GetTutorials,
  GetUserSession,
  GetDataBlock,
  GetPlatform
};
