import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

import "../scss/Tutorials.scss";
import { Box } from "@material-ui/core";

function Tutorials({ tutorials }) {
  const openTutorial = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div id="tutorials">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h3">
            {tutorials
              ? "Dicas e Tutoriais"
              : "Em breve, dicas e tutoriais para te ajudar!"}
          </Typography>
        </Grid>
        {tutorials &&
          tutorials.map((tutorial, i) => (
            <Grid item xs={12} sm={4} key={`tutorial${i}`}>
              <Card className="card">
                <CardActionArea onClick={() => openTutorial(tutorial.link)}>
                  <div className="bar"></div>
                  <CardContent>
                    <Box display="flex" alignItems="flex-start">
                      <span>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          dangerouslySetInnerHTML={{ __html: tutorial.text }}
                        ></Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {tutorial.description}
                        </Typography>
                      </span>
                      {tutorial.hasInfoIcon && (
                        <Box paddingLeft="8px">
                          <img
                            src="/img/info-icon.png"
                            height={24}
                            width={24}
                            alt="Ícone de informação"
                          />
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default Tutorials;
