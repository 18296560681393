import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import Alert from "../components/Alert";
import { GetPlatform, GetDownloadLink } from "../api";

import "../scss/Subscribes.scss";

function Subscribes(props) {
  const { inscriptions, loading } = props;
  const [platform, setPlatform] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    setPlatform(GetPlatform());
  }, []);

  const closeAlert = () => {
    setAlert(false);
  };

  const download = async (inscription, platform) => {
    loading(true);

    try {
      const response = await GetDownloadLink(inscription, platform);
      const a = document.createElement("a");
      const body = document.querySelector("body");

      a.style.display = "none";
      a.setAttribute("href", response.data);
      body.append(a);
      a.click();
    } catch (error) {
      setAlert({ message: "Não foi possível fazer o download.", error: error });
    }

    loading(false);
  };

  const goToPanel = () => window.open("https://uol.my.kaspersky.com", "_blank");

  return (
    <div id="subscribes">
      <Grid container spacing={2}>
        {inscriptions &&
          inscriptions.map((inscription) => (
            <Grid item xs={12} sm={4} key={inscriptions.map(((_, index) => index + 1))}>
              <div className="product">
                <div className="product-status"></div>
                <div className="product-body">
                  <Typography
                    className="product-name"
                    variant="h3"
                    component="h3"
                  >
                    {inscription.description}
                  </Typography>
                  <ul className="product-details">
                    <li>
                      <InfoIcon className="icon" />
                      <Typography>{inscription.status}</Typography>
                    </li>
                  </ul>
                  <div className="product-actions">
                    <Link component="button" size="small" color="primary" onClick={goToPanel}>
                      GERENCIAR
                    </Link>
                    {platform && (
                      <Link
                        component="button"
                        size="small"
                        color="primary"
                        onClick={() =>
                          download(inscription.idtInscription, platform)
                        }
                      >
                        INSTALAR
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
      {alert && <Alert alert={alert} close={closeAlert} />}
    </div>
  );











  // return (
  //   <div id="subscribes">
  //     <Grid container spacing={2}>
  //       {inscriptions &&
  //         inscriptions.map((inscription) => (
  //           <Grid item xs={12} sm={4} key={inscription.idtInscription}>
  //             <div className="product">
  //               <div className="product-status"></div>
  //               <div className="product-body">
  //                 <Typography
  //                   className="product-name"
  //                   variant="h3"
  //                   component="h3"
  //                 >
  //                   {inscription.product.description}
  //                 </Typography>
  //                 <ul className="product-details">
  //                   <li>
  //                     <InfoIcon className="icon" />
  //                     <Typography>{inscription.status.desStatus}</Typography>
  //                   </li>
  //                 </ul>
  //                 <div className="product-actions">
  //                   <Link component="button" size="small" color="primary" onClick={goToPanel}>
  //                     GERENCIAR
  //                   </Link>
  //                   {platform && (
  //                     <Link
  //                       component="button"
  //                       size="small"
  //                       color="primary"
  //                       onClick={() =>
  //                         download(inscription.idtInscription, platform)
  //                       }
  //                     >
  //                       INSTALAR
  //                     </Link>
  //                   )}
  //                 </div>
  //               </div>
  //             </div>
  //           </Grid>
  //         ))}
  //     </Grid>
  //     {alert && <Alert alert={alert} close={closeAlert} />}
  //   </div>
  // );
}

export default Subscribes;
