import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import '../scss/NotFound.scss';

function NotFound() {
  return (
    <Container maxWidth="md" className="not-found">
      <Grid container>
        <Grid item xs={12}>
          <div className="message">
            <ErrorOutlineIcon className="icon" />
            <Typography component="h3">Ops! Página não encontrada!</Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NotFound;
